import React from "react"
import Layout from "../components/Layout/Layout"
import BlogList from "../components/BlogList"

export default function IndexPage() {
  return (
    <Layout page="home" bgColor="inherit" title="aboutroots.">
        <BlogList />
    </Layout>
  )
}